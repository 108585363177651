import React from 'react';
import Link from 'gatsby-link';
import klachtenprocedurePdf from '../assets/klachtenprocedure_middelbeek_van_der_linden.pdf';

const Footer = () => (
  <div className="px-4 bg-gray-700 text-gray-100">
    <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-4 pt-14 pb-20">
      <div>
        <h1 className="text-lg text-gray-400 mb-2">Ga naar</h1>
        <ul>
          <li>
            <Link
              className={`font-medium text-gray-100 hover:text-gray-50`}
              to="/bewindvoering"
            >
              Bewindvoering
            </Link>
          </li>
          <li>
            <Link
              className={`font-medium text-gray-100 hover:text-gray-50`}
              to="/budgetbeheer"
            >
              Budgetbeheer
            </Link>
          </li>
          <li>
            <Link
              className={`font-medium text-gray-100 hover:text-gray-50`}
              to="/mentorschap"
            >
              Mentorschap
            </Link>
          </li>
          <li>
            <Link
              className={`font-medium text-gray-100 hover:text-gray-50`}
              to="/curatele"
            >
              Curatele
            </Link>
          </li>
          <li>
            <Link
              className={`font-medium text-gray-100 hover:text-gray-50`}
              to="/tarieven"
            >
              Tarieven
            </Link>
          </li>
          <li>
            <a
              className={`font-medium text-gray-100 hover:text-gray-50`}
              href={klachtenprocedurePdf}
              rel="noreferrer"
              target="_blank"
            >
              Klachtenprocedure
            </a>
          </li>
        </ul>
      </div>
      <div className="md:text-right">
        <h1 className="text-lg text-gray-400 mb-2">Postadres</h1>
        <address className="not-italic">
          Middelbeek & Van der Linden Bewindvoering
          <br />
          Postbus 3076
          <br />
          1801 GB Alkmaar
        </address>
      </div>
    </div>
    <div className="text-center py-4 text-gray-500">
      Middelbeek & Van der Linden Bewindvoering
    </div>
  </div>
);

export default Footer;
