import React from 'react';
import Link from 'gatsby-link';

const topBarClasses = [
  'bg-gradient-to-r',
  'bg-white',
  'border-b',
  'border-gray-200',
  'flex',
  'flex-wrap',
  'from-white',
  'to-gray-100',
  'w-full',
];

const TopBar = ({ children }) => (
  <div className={topBarClasses.join(' ')}>{children}</div>
);

const LinkBox = ({ children }) => (
  <div className="h-12 md:h-14 flex flex-col justify-center">{children}</div>
);

const menuItemClassName = (active) =>
  `font-medium text-cyan-600 hover:underline active:underline ml-4 md:ml-8 ${
    active ? 'underline' : ''
  }`;

const Header = ({ location }) => {
  const pathname = location?.pathname || '';

  return (
    <TopBar>
      <LinkBox>
        <Link className={menuItemClassName(pathname === '/')} to="/">
          Home
        </Link>
      </LinkBox>
      <LinkBox>
        <Link
          className={menuItemClassName(pathname.includes('bewindvoering'))}
          to="/bewindvoering"
        >
          Bewindvoering
        </Link>
      </LinkBox>
      <LinkBox>
        <Link
          className={menuItemClassName(pathname.includes('budgetbeheer'))}
          to="/budgetbeheer"
        >
          Budgetbeheer
        </Link>
      </LinkBox>
      <LinkBox>
        <Link
          className={menuItemClassName(pathname.includes('mentorschap'))}
          to="/mentorschap"
        >
          Mentorschap
        </Link>
      </LinkBox>
      <LinkBox>
        <Link
          className={menuItemClassName(pathname.includes('curatele'))}
          to="/curatele"
        >
          Curatele
        </Link>
      </LinkBox>
      <LinkBox>
        <Link
          className={menuItemClassName(pathname.includes('tarieven'))}
          to="/tarieven"
        >
          Tarieven
        </Link>
      </LinkBox>
    </TopBar>
  );
};

export { Header };
