import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

import { Header } from '../components/header';
import { Hero } from '../components/hero';
import Footer from '../components/footer';

const Layout = ({ children, location }) => (
  <>
    <Helmet
      title="Bewindvoering en mentorschap in Noord-Holland - Middelbeek & Van der Linden"
      meta={[
        {
          name: 'keywords',
          content:
            'bewindvoering, mentorschap, financiële ondersteuning, noord-holland',
        },
      ]}
    />
    <Header location={location} />
    <Hero />
    <div className="max-w-4xl mx-auto px-4">
      <div className="py-10">{children}</div>
    </div>
    <Footer />
  </>
);

Layout.propTypes = {
  children: PropTypes.node,
};

export { Layout };
