import React from 'react';
import headerImage from '../assets/header-image.jpg';

import './hero.css';

const Hero = () => (
  <div
    className="h-80"
    style={{
      background: `url(${headerImage}) no-repeat center center`,
      backgroundSize: 'cover',
    }}
  >
    <div className="h-full flex flex-col justify-center px-4 max-w-4xl mx-auto gap-4">
      <h1 className="hero-shadow text-white text-3xl md:text-4xl font-bold">
        Middelbeek & Van der Linden
      </h1>
      <h2 className="hero-shadow text-white text-2xl md:text-3xl font-bold">
        Bewindvoering en mentorschap in Noord-Holland
      </h2>
    </div>
  </div>
);

export { Hero };

export default Hero;
